import { render, staticRenderFns } from "./App.vue?vue&type=template&id=920c854a&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "920c854a",
  null
  
)

component.options.__file = "App.vue"
export default component.exports