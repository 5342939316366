<template>

	<div class="page" id="home">

		<div class="page-heading">
			<AnimatedLines :duration="5000" :lines="introLines"/>
			<h1>vineco</h1>
		</div>

		<div class="container">
			<div class="row no-gutters block-layout">
				<div class="col-12 col-lg block-item block-accent block-image left">

					<img src="../assets/front-page/ecological.jpg" alt="Ecological"/>

					<div class="block-content text-white">
						<h4>What makes vineco</h4>
						<h3 class="text-primary">ecological</h3>
						<p class="lead">
							Vineco is a great alternative for unsustainable and non-ecological products because it's made of from natural sources such as vinegar and water.
						</p>
					</div>

				</div>
				<div class="col-12 col-lg block-item block-accent block-image right">

					<img src="../assets/front-page/environment.jpg" alt="Environment"/>

					<div class="block-content text-white">
						<h4>Vineco thinks about</h4>
						<h3 class="text-primary">the environment</h3>
						<p class="lead">
							To make the transport of vineco more sustainable, we decided to only sell vinegar in our bottles because everyone has water at home. We created a foldable bottle in the way that it saves some weight and space during transport.
						</p>
					</div>

				</div>
			</div>

			<div class="row no-gutters block-layout">
				<div class="col-12 col-lg block-item block-primary-light block-image full-content">

					<img src="../assets/front-page/kitchen.jpg" alt="Kitchen"/>

					<div class="block-content text-white">
						<h4>Cleaning in</h4>
						<h3>the kitchen</h3>
						<p class="lead">
							In the kitchen, you often have to deal with chalk and greasy stains. Do you want to get rid of this in an ecological way?
						</p>
						<button class="btn btn-contained btn-primary"><i class="mdi mdi-arrow-right-bold-circle"></i><span>Buy now!</span></button>
					</div>

				</div>
				<div class="col-12 col-lg block-item block-primary-light block-image full-content">

					<img src="../assets/front-page/bathroom.jpg" alt="Bathroom"/>

					<div class="block-content text-white">
						<h4>Cleaning in</h4>
						<h3>the bathroom</h3>
						<p class="lead">
							There are many problems that can occur in the bathroom, think of a calcified shower head, a dirty toilet or other crazy stains. How do you solve this?
						</p>
						<button class="btn btn-contained btn-primary"><i class="mdi mdi-arrow-right-bold-circle"></i><span>Buy now!</span></button>
					</div>

				</div>
				<div class="col-12 col-lg block-item block-primary-light block-image full-content">

					<img src="../assets/front-page/tips.jpg" alt="Cleaning tips"/>

					<div class="block-content text-white">
						<h4>Other</h4>
						<h3>cleaning tips</h3>
						<p class="lead">
							You can use vineco for almost anything to clean. Think of cleaning windows, home accessories, but also your computer and mouse.
						</p>
						<button class="btn btn-contained btn-primary"><i class="mdi mdi-arrow-right-bold-circle"></i><span>Buy now!</span></button>
					</div>

				</div>
			</div>
		</div>

	</div>

</template>

<script>

	import AnimatedLines from "../component/AnimatedLines";

	export default {

		name: "home",

		components: {AnimatedLines},

		data()
		{
			return {
				introLines: [
					"An ecological cleaning solution",
					"Dissolving chalk and greasy stains",
					"An alternative product for unsustainable cleaning products",
					"Fits in every home"
				]
			};
		}
	}

</script>
