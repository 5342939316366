<template>

	<h2 :class="{'is-hidden': isHidden}">{{ line }}</h2>

</template>

<script>

	export default {

		name: "AnimatedLines",

		props: {

			duration: {
				default: 5000,
				required: false,
				type: Number
			},

			lines: {
				default: () => [],
				required: true,
				type: Array
			}

		},

		data()
		{
			return {
				index: -1,
				isHidden: true,
				timeout: null
			};
		},

		destroyed()
		{
			clearTimeout(this.timeout);
		},

		mounted()
		{
			this.next();
		},

		computed: {

			line()
			{
				return this.lines[this.index % this.lines.length] || undefined;
			}

		},

		methods: {

			next()
			{
				const next = () =>
				{
					this.index++;
					this.isHidden = false;
					this.timeout = setTimeout(() => this.next(), this.duration);
				};

				if (this.line === undefined)
				{
					next();
				}
				else
				{
					this.isHidden = true;

					setTimeout(() => next(), 500);
				}
			}

		}

	}

</script>

<style lang="scss" scoped>

	h2
	{
		transition: opacity 500ms var(--ease-swift-out);
		will-change: opacity;

		&.is-hidden
		{
			opacity: 0;
		}
	}

</style>
